

.App {
  text-align: center;
  height: 100%;
  z-index: 2;
  /* overflow-x: hidden; */
}


.light {
  color: rgb(8, 10, 32);
}
.dark {
  color: rgb(255, 255, 255);
}



/* 
/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #ffffff;
  /* min-height: 100vh;
  display: flex;
   flex-direction: column; 
  align-items:baseline;
  justify-content:space-between;
  font-size: calc(10px + 2vmin); */
  color: rgb(8, 10, 32);
}
.App-header-black {
  background-color: #000000;
  /* min-height: 100vh;
  display: flex;
   flex-direction: column; 
  align-items:baseline;
  justify-content:space-between;
  font-size: calc(10px + 2vmin); */
  color: rgb(213, 214, 233);
}
/* 
.App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


/* 
.swiper-container {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
} */